import { Flex, Spinner, Stack, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { EducationResult } from "../spelling/game-play/result-chart/EducationResult";
import { TeamBondingResult } from "../spelling/game-play/result-chart/TeamBondingResult";
import { useState, useEffect } from "react";
import { useApiService } from "../../redux-action/api.service";
import { Score } from "../../types/score-types";
import { Background } from "../../common/background/Background";


export const ResultDetails = () => {
  const { gameId, category } = useParams();
  const api = useApiService();
  const [score, setScore] = useState<Score>({} as Score);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);


  useEffect(() => {
    const fetchScore = async () => {
      const response = await api.service(`game-scores/score/${gameId}`).find();
      setScore(response);
    };

    if (gameId && category) {
      try {
        setIsLoading(true);
        fetchScore();
      } catch (err) {
        console.log(err);
        setError(err instanceof Error ? err : new Error('Failed to fetch score'));
      } finally {
        setIsLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  
  if (isLoading) {
    return <Spinner />;
  }
  if (error) {
    return <Text color="red.500">Error loading game results: {error.message}</Text>;
  }


  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}  bg={"transparent"}>
      <Background />
      <Stack spacing={8} mx={"auto"} width={"full"} py={12} px={6}>
        {category === "TeamBonding" ? (
          <TeamBondingResult score={score} isBackBtn={true} isAuthView={true} />
        ) : (
          <EducationResult score={score} isBackBtn={true} />
        )}
      </Stack>
    </Flex>
  );
};
