import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  Heading,
  Link,
  Spinner,
  ListItem,
  UnorderedList,
  ListIcon,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import zxcvbn from "zxcvbn";


import { useParams } from "react-router-dom";
import {
  ResetPasswordValidationSchema,
  ResetPasswordValidation,
} from "./schema";
type CriteriaType = "length" | "uppercase" | "lowercase" | "special_character";

export const ResetPassword = () => {

  let { email, token } = useParams();
  let decodedEmail;
  try {
    decodedEmail = JSON.parse(atob(email ?? ""));
  } catch (error) {}
  // const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    watch,
  } = useForm({
    resolver: yupResolver(ResetPasswordValidationSchema),
    defaultValues: {
      password: "",
      email: decodedEmail?.email ?? "null",
      token: token ?? "null",
    },
  });

  const password = watch("password");

  const onSubmitHandler = async (data: ResetPasswordValidation) => {
    try {
     
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const checkPasswordCriteria = (
    criteriaType: CriteriaType,
    password: string,
  ): boolean => {
    const criteria: Record<CriteriaType, () => boolean> = {
      length: () => password?.length > 7,
      uppercase: () => /[A-Z]/.test(password),
      lowercase: () => /[a-z]/.test(password),
      special_character: () => /[\W_]/.test(password),
    };

    return criteria[criteriaType] ? criteria[criteriaType]() : false;
  };

  const getPasswordStrength = (password: string): string => {
    if (!password) return "";

    const { score } = zxcvbn(password);
    return ["Weak", "Medium", "Medium", "Strong", "Strong"][score];
  };

  const isPasswordValid = (password: string): boolean => {
    return ["length", "uppercase", "lowercase", "special_character"].every(
      (type) => checkPasswordCriteria(type as CriteriaType, password),
    );
  };

  const getClassColor = (strength: string): string => {
    const colors: Record<string, string> = {
      Weak: "red.600",
      Medium: "yellow.600",
      Strong: "green.600",
    };
    return colors[strength] || "";
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} py={12} px={6}>
        <Box rounded={"lg"} bg={"gray.50"} boxShadow={"lg"} p={8}>
          <Box as={"form"} mt={0} onSubmit={handleSubmit(onSubmitHandler)}>
            <Flex direction="column" w="100%" background="transparent">
              <Heading color={"#"} fontSize={25} mb={5} textAlign={"center"}>
                Password Reset
              </Heading>
              <Text mb={5}>Enter your new password for your account</Text>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Password
                </FormLabel>
                <Input
                  type="password"
                  {...register("password")}
                  isInvalid={!!errors.password}
                  mb="24px"
                  fontSize="sm"
                  placeholder="Password"
                  size="lg"
                />

                <UnorderedList>
                  <ListItem>
                    Password must be 8 or more characters
                    {checkPasswordCriteria("length", password) ? (
                      <ListIcon as={CheckIcon} color="green.500" ml={3} />
                    ) : (
                      ""
                    )}
                  </ListItem>
                  <ListItem>
                    Password must have at least one uppercase character
                    {checkPasswordCriteria("uppercase", password) ? (
                      <ListIcon as={CheckIcon} color="green.500" ml={3} />
                    ) : (
                      ""
                    )}
                  </ListItem>
                  <ListItem>
                    Password must have at least one lowercase character
                    {checkPasswordCriteria("lowercase", password) ? (
                      <ListIcon as={CheckIcon} color="green.500" ml={3} />
                    ) : (
                      ""
                    )}
                  </ListItem>
                  <ListItem>
                    Password must have at least one special character
                    {checkPasswordCriteria("special_character", password) ? (
                      <ListIcon as={CheckIcon} color="green.500" ml={3} />
                    ) : (
                      ""
                    )}
                  </ListItem>
                </UnorderedList>
                {password ? (
                  <>
                    <Text mt={3}>
                      Password strength:{" "}
                      <Text
                        as={"strong"}
                        color={getClassColor(getPasswordStrength(password))}
                      >
                        {getPasswordStrength(password)}
                      </Text>
                    </Text>
                  </>
                ) : (
                  ""
                )}
                {isPasswordValid(password) ? (
                  <Button
                    type="submit"
                    disabled={false}
                    mt={7}
                    w="100%"
                    h="45"
                    mb="20px"
                    colorScheme="blue"
                  >
                    {isSubmitting ? <Spinner /> : "Reset Password"}
                  </Button>
                ) : (
                  <Button disabled={false} mt={7} w="100%" h="45" mb="20px">
                    Reset Password
                  </Button>
                )}
              </FormControl>
              <Flex
                flexDirection="column"
                justifyContent="left"
                alignItems="left"
                maxW="100%"
                mt="0px"
              >
                <Text color={"gray.700"} fontWeight="medium">
                  Remember my password?
                  <Link color={"#2b6cb0"} as="a" href="/sign-in" ms="5px">
                    Sign In
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Stack>
    </Flex>
  );
};
