import { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Input,
  Stack,
  Button,
  Text,
  Flex,
  Heading,
  useToast,
  Center,
  VStack,
  Progress,
} from "@chakra-ui/react";
import { QuestionsCard } from "./question";
import { GameHeaderCard } from "./GameHeaderCard";
import {
  validateNumberQuestion,
  validateQuestion,
} from "./helper";
import { useGameComplete } from "../../../hooks/useGameComplete";
import { AnimatedBackground } from "./result-chart/AnimatedBackground";
import { useAnswerFeedback } from "../../../hooks/useAnswerFeedback";
import ContentCard from "../../nav/ContentCard";
import { backgroundImages } from "./BgImages";
import { Background } from "../../../common/background/Background";
import { useSocket } from "../../../hooks/use-socket";
import { useGame } from "../../../hooks/use-game";
import { useAppDispatch } from "../../../redux-action/hooks/useTypedSelector";
import { api } from '../../../redux-action/api.service';
import { setCurrentScore } from "../../../redux-action/slices/game-slice";
import { setCurrentQuestionIndex, selectCurrentQuestionIndex } from "../../../redux-action/slices/question-number-slice";
import { useSelector } from "react-redux";
import { LoadingScoreCalculation } from "./LoadingScoreCalculation";


export const SpellingGamePlay = () => {
  const {
    instruction,
    questions,
    currentScore
  } = useGame();

  const currentQuestionIndex = useSelector(selectCurrentQuestionIndex);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [gameScore, setGameScore] = useState<number>(currentScore);
  const [answerAlert, setAnswerAlert] = useState<any>();
  const { gameComplete } = useGameComplete();
  const { renderAnswer } = useAnswerFeedback();
  const { playersScores, updateScore } = useSocket();
  const [isHideLivePlayersScore, setIsHideLivePlayersScore] =
    useState<boolean>(false);

  const [bgImage] = useState(
    () => backgroundImages[Math.floor(Math.random() * backgroundImages.length)],
  );

  const [clickSnd] = useState(new Audio("/sounds/click.wav"));

  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });

  const dispatch = useAppDispatch();
  const toast = useToast();

  const onSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    clickSnd.play();
    try {
      const currentQuestion = questions[currentQuestionIndex];
      
      const answerData = {
        ...formData,
        question: validateQuestion(JSON.stringify(currentQuestion.question)),
        type: currentQuestion.type,
        hiddenValue: currentQuestion.hiddenValue ?? null,
      }
      const response = await api.service("game-engine/check-answer").create(answerData);
      
      // First update the score in game state
      const newScore = response.score + currentScore;
      await dispatch(setCurrentScore(newScore));
      setGameScore(newScore);

      // Then update socket with new score
      updateScore(newScore); // Pass the new score

      dispatch(setCurrentQuestionIndex(currentQuestionIndex + 1));
      
      setAnswerAlert(renderAnswer(response.score, response.message));

      setShowFeedback(true);
      setTimeout(() => {
        setShowFeedback(false);
      }, 2000);
      setFormData({
        ...formData,
        answer: "",
      });
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    checkGameComplete();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex, gameScore]);

  const checkGameComplete =  async () => {
    if (currentQuestionIndex >= questions?.length) {
      await gameComplete();
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value.toUpperCase(),
    });
  };

  const onGameOver = async () => {
    await gameComplete();
  };

  const renderPlayerScores = () =>
    Object.entries(playersScores).map(([id, data]) => (
      <Box
        key={id}
        p={2}
        color="#FFF"
        borderRadius="md"
        bg="rgba(0, 0, 0, 0.5)"
        mt={2}
      >
        <Text textAlign={"left"} textTransform={"capitalize"}>
          {data.name} : <strong> {data.score}</strong>
        </Text>
      </Box>
    ));

  if (currentQuestionIndex >= questions?.length) {
    return <LoadingScoreCalculation />;
  }

  return (
    <ContentCard
      position="relative"
      bgImage={`url('${bgImage}')`}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      width={"100%"}
      borderRadius={0}
      bg={"transparent"}
      p={0}
    >
      <Background />
      <AnimatedBackground />
      <GameHeaderCard
        currentQuestionIndex={currentQuestionIndex}
        totalQuestions={questions?.length}
        score={gameScore}
        onGameOver={onGameOver}
      />

      <Flex
        align="flex-start"
        width="100%"
        direction={{ base: "column", md: "row" }}
        gap={6}
        p={4}
      >
        <Stack
          width={{ base: "100%", md: "70%" }}
          p={4}
          borderRadius="md"
          bg="transparent"
          zIndex={1000}
        >
          {currentQuestionIndex < questions?.length && (
            <Box flex={1}>
              <QuestionsCard
                defaultInstruction={instruction}
                instruction={questions[currentQuestionIndex].instructions}
                question={validateNumberQuestion(
                  questions[currentQuestionIndex].question,
                )}
              />
            </Box>
          )}
          <Box as="form" mt={3} onSubmit={onSubmit}>
            <FormControl id="Answer" w="full">
              <Input
                type="text"
                id="answer"
                onChange={onChange}
                placeholder="Answer"
                value={formData.answer}
                fontSize={34}
                h={50}
                padding={2}
                bg="#FFF"
                onPaste={(e) => {
                  e.preventDefault();
                  toast({
                      title: 'Warning',
                      description: 'Please type the answer manually',
                      status: 'warning',
                      duration: 2000
                  });
              }}
                autoComplete="off"
                spellCheck="false"
              />
            </FormControl>
            <Button type="submit" colorScheme="gray" w="full" size="lg" mt={3}>
              Submit
            </Button>
            <Text textAlign="center" mt={0}>
              {showFeedback && answerAlert}
            </Text>
          </Box>
        </Stack>
        {/* Right Column */}
        <Box
          width={{ base: "100%", md: "30%" }}
          border="1px #FFF solid"
          p={4}
          mt={3}
          borderRadius="md"
        >
          <Button
            float={"right"}
            onClick={() => setIsHideLivePlayersScore(!isHideLivePlayersScore)}
          >
            {isHideLivePlayersScore ? "Show" : "Hide"} Score
          </Button>
          <Heading color={"#FFF"} fontSize={17} mb={7}>
            Live Player Scores
          </Heading>
          {!isHideLivePlayersScore && renderPlayerScores()}
        </Box>
      </Flex>
    </ContentCard>
  );
};
