import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Img,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdPlayCircleFilled } from "react-icons/md";
import { Background } from "../../common/background/Background";
import { MotionElement } from "../../components/MotionElement";

export const Hero = () => {
  const { isOpen } = useDisclosure();
  return (
    <Box px={4}>
      <Background isDynamic={true} />
      <Container maxW="6xl" sx={{ p: 0, mt: { base: 4, md: 0 } }}>
        <Flex
          gap={2}
          alignItems="center"
          direction={{ base: "column", md: "row" }}
        >
          <Flex direction="column" flexBasis={{ base: "0", md: "580px" }}>
            <Heading fontSize={{ base: "2xl", md: "3xl" }} mt={9} color="white">
              Find out where your{" "}
              <Text
                as="span"
                color="#F26B3A"
                p={1}
                borderRadius="lg"
                boxShadow={"lg"}
                bg="#f5f5f980"
              >
                Memory IQ
              </Text>{" "}
              stands compared with others.
            </Heading>
            <Text as="h2" color="white" fontSize={20} mt={4} display="block">
              Ready to lay your way to a better memory? Start Today and get a
              FREE Certificate!
            </Text>
            <Box w={"100%"}>
              <MotionElement type="fadeInX">
                {!isOpen && (
                  <SimpleGrid
                    columns={{ base: 1, md: 1, lg: 1, xl: 2, "2xl": 2 }}
                    spacing={5}
                    borderRadius="lg"
                  >
                    <Button
                      data-testid="start-new-game"
                      // onClick={onOpen}
                      as={"a"}
                      href={"/sign-in"}
                      colorScheme="yellow"
                      mt={8}
                      size="lg"
                      rightIcon={<MdPlayCircleFilled size={22} />}
                    >
                      Start M-IQ with your team
                    </Button>
                    <Button
                      as={"a"}
                      href={`${window.location.origin}/spell/0ffe08be-eff6-4817-b798-a6e81dfe8402`}
                      colorScheme="yellow"
                      mt={8}
                      size="lg"
                      rightIcon={<MdPlayCircleFilled size={22} />}
                    >
                      Test My Memory IQ Now!
                    </Button>
                  </SimpleGrid>
                )}
              </MotionElement>
            </Box>
          </Flex>
          <Flex>
            <Box mt={0} width={"100%"}>
              <Img src="/images/illustrations/hero-5.svg" alt="illustration" />
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
