import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  Icon,
  Box,
  Heading,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaPlus } from "react-icons/fa";
import { CreateGameForm } from "../../components/CreateGameForm";
import { GameSchema } from "./Schema";
import { useApiService } from "../../redux-action/api.service";
import { useGames } from "../../context/GamesContext";
import { useUser } from "../../hooks/use-user";
import { updateUserCredits } from "../../redux-action/slices/auth-slice";
import { AppDispatch } from "../../redux-action/store";
import { useDispatch } from "react-redux";

export const CreateGame = () => {
  const api = useApiService();
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { refreshGames } = useGames();
  const dispatch = useDispatch<AppDispatch>();
  
  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting, errors },
  } = useForm<GameSchema>({
    resolver: yupResolver(GameSchema),
    defaultValues: {
      numberOfQuestions: 3,
      gameTime: 120,
    },
  });

  const onSubmitHandler = async (data: GameSchema) => {
    try {
      const gameData = {
        ...data,
        expiryDate: new Date(data.expiryDate).toISOString(),
      };
    
      const response = await api.service('games').create(gameData);
      
      if (response) {
        // Calculate and update credits
        const updatedCredits = (user?.credit ?? 0) - data.expectedUsers;
        dispatch(updateUserCredits(updatedCredits));

        toast({
          title: "Game created successfully",
          description: `${data.expectedUsers} credits have been deducted from your account.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        
        onClose();
        await refreshGames();
      }
    } catch (error: any) {
      toast({
        title: "Error creating game",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        leftIcon={<Icon as={FaPlus} />}
        onClick={onOpen}
        colorScheme="blue"
        size="lg"
      >
        Create Memory IQ
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size="lg">Create Memory IQ Test</Heading>
          </ModalHeader>
          <ModalCloseButton />
          
          <ModalBody p={3}>
            <Box as="form" onSubmit={handleSubmit(onSubmitHandler)}>
              <CreateGameForm
                register={register}
                errors={errors}
                control={control}
                isSubmitting={isSubmitting}
              />
              
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
