import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  Heading,
  Link,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ForgetPasswordSchema } from "./schema";
import { api } from "../../redux-action/api.service";

export const ForgetPassword = () => {
  const toast = useToast();

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(ForgetPasswordSchema),
  });

  const onSubmitHandler = async (email: ForgetPasswordSchema) => {
    try {
      const response = await api.service('email/reset-password').create(email);
      if(response) {
       toast({
         title: "We've sent you an email to reset your password.",
         description: "Success",
         status: "success",
         position: "top-right",
         duration: 9000,
         isClosable: true,
       });
       reset();
     }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Box rounded={"lg"} bg={"gray.50"} boxShadow={"lg"} p={8}>
          <Box as={"form"} mt={0} onSubmit={handleSubmit(onSubmitHandler)}>
            <Flex direction="column" w="100%" background="transparent">
              <Heading color={"#"} fontSize={25} mb={5} textAlign={"center"}>
                Reset your password
              </Heading>
              <Text mb={5}>
                Please provide the email address you used when you signed up for
                yourThelastSpellingBee account.
              </Text>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  type="email"
                  {...register("email")}
                  isInvalid={!!errors.email}
                  mb="24px"
                  fontSize="sm"
                  placeholder="Email"
                  size="lg"
                />

                <Button
                  type="submit"
                  w="100%"
                  h="45"
                  mb="20px"
                  colorScheme="blue"
                >
                  {isSubmitting ? <Spinner /> : "Send Email"}
                </Button>
              </FormControl>
              <Flex
                flexDirection="column"
                justifyContent="left"
                alignItems="left"
                maxW="100%"
                mt="0px"
              >
                <Text color={"gray.700"} fontWeight="medium">
                  Remember my password?
                  <Link color={"#2b6cb0"} as="a" href="/sign-in" ms="5px">
                    Sign In
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Stack>
    </Flex>
  );
};
