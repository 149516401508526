import { FC } from "react";
import { Box, Heading, Progress, Stack, Text } from "@chakra-ui/react";
import { Question } from "./Question";

type QuestionsCardProps = {
  defaultInstruction: string;
  instruction: string;
  question: string | string[];
};

export const QuestionsCard: FC<QuestionsCardProps> = ({
  defaultInstruction,
  instruction,
  question,
}) => {
  return (
    <Box width={"100%"}>
      <>
        <Box bg={"#FFF"} width={"100%"} borderRadius={4} pb={4} opacity={0.8}>
          <Stack
            bg={[
              "black",
              "green", 
              "blue.700"
            ][Math.floor(Math.random() * 3)]}
            p={{ base: "16px 16px 7px 16px", sm: "24px 24px 12px 24px" }}
            borderRadius={"4px 4px 0px 0px"}
            mb={4}
            alignItems="start"
          >
            <Stack
              w="full"
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Stack direction="row" alignItems="center" borderRadius="md">
                <Text color="white" fontWeight="bold" fontSize={18}  opacity={1}>
                  {defaultInstruction}
                </Text>
              </Stack>
            </Stack>

            <Text
              fontWeight="bold"
              fontSize={{ base: 16, sm: 29 }}
              color="whiteAlpha.800"
              lineHeight="6"
              opacity={1}
            >
              {instruction}
            </Text>
            <Progress
              w="full"
              value={Math.round((Date.now() % 26000) / 260)} 
              size="xs"
              background="blackAlpha.400"
              sx={{
                "&>div": {
                  bg: "white",
                  transition: "width 26s linear",
                  animation: "slideProgress 26s linear infinite"
                },
                "@keyframes slideProgress": {
                  "0%": { width: "0%" },
                  "100%": { width: "100%" }
                }
              }}
            />
          </Stack>
          <Heading fontSize={["2xl", "3xl"]} textAlign="center">
            {Object.keys(question).map((key, index) => (
              <Question key={index} question={question[index]} index={index} />
            ))}
            ?
          </Heading>
        </Box>
      </>
    </Box>
  );
};
