import {
  Box,
  Text,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { FaClock } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { useGame } from "../../../hooks/use-game";
import { useAppDispatch } from "../../../redux-action/hooks/useTypedSelector";
import { setTimeCompleted } from "../../../redux-action/slices/game-slice";
import { useGameComplete } from "../../../hooks/useGameComplete";
import { LoadingScoreCalculation } from "./LoadingScoreCalculation";

type GameTimeProps = {
  onTimerEnd?: (currentGameTime: string) => void;
};

export const formatTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours}:${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds,
  ).padStart(2, "0")}`;
};

export const GameTime: React.FC<GameTimeProps> = ({ onTimerEnd }) => {
  const { game } = useGame();
  const dispatch = useAppDispatch();
  const { gameComplete } = useGameComplete();
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isTimerLoading, setIsTimerLoading] = useState<boolean>(false);

  useEffect(() => {
    if (game && !isInitialized) {
      const startTimeKey = `game_start_time_${game.id}`;
      const storedStartTime = localStorage.getItem(startTimeKey);

      if (storedStartTime) {
        // Calculate remaining time based on stored start time
        const elapsedSeconds = Math.floor(
          (Date.now() - parseInt(storedStartTime)) / 1000,
        );
        const totalGameSeconds = game.gameTime * 60;
        const remainingTime = Math.max(totalGameSeconds - elapsedSeconds, 0);
        setTimeRemaining(remainingTime);
      } else {
        // First time starting the game
        localStorage.setItem(startTimeKey, Date.now().toString());
        setTimeRemaining(game.gameTime * 60);
      }

      setIsInitialized(true);
    }
  }, [game, isInitialized]);

  const handleGameOver = async () => {
    try {
      setIsTimerLoading(true);
      await gameComplete();
    } catch (error: any) {
      console.error("Error completing game:", error.message);
    } finally {
      setIsTimerLoading(false);
    }
  };

  useEffect(() => {
    if (timeRemaining === null || !isInitialized || !game) {
      return;
    }

    if (timeRemaining === 0) {
      const formattedTime = formatTime((game.gameTime || 0) * 60);
      if (onTimerEnd) {
        onTimerEnd(formattedTime);
      }
      handleGameOver();
      return;
    }

    const timerId = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === null) return null;
        if (prevTime <= 0) return 0;
        dispatch(setTimeCompleted(formatTime(prevTime)));
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining, isInitialized, game]);

  if (!game) {
    return <CircularProgress isIndeterminate color="blue.500" size="40px" />;
  }

  const totalTime = game.gameTime * 60;
  const progress = ((timeRemaining ?? 0) / totalTime) * 100;
  const isLowTime = (timeRemaining ?? 0) <= 30;

  if (isTimerLoading) {
    return <LoadingScoreCalculation />;
  }

  return (
    <HStack
      spacing={4}
      bg={isLowTime ? "red.50" : "white"}
      p={1}
      borderRadius="md"
      shadow="sm"
      transition="all 0.2s"
      animation={isLowTime ? "pulse 1.5s infinite" : "none"}
      w={"170px"}
    >
      <CircularProgress
        value={progress}
        color={isLowTime ? "red.400" : "blue.400"}
        thickness="12px"
        size="40px"
      >
        <CircularProgressLabel>
          <Icon
            as={FaClock}
            color={isLowTime ? "red.500" : "blue.500"}
            w={5}
            h={5}
          />
        </CircularProgressLabel>
      </CircularProgress>
      <Box>
        {/* <Text 
          fontSize="sm" 
          color={isLowTime ? "red.500" : "blue.500"} 
          fontWeight="medium"
        >
          Time Remaining
        </Text> */}
        <Text
          fontSize="xl"
          fontWeight="bold"
          color={isLowTime ? "red.600" : "blue.600"}
        >
          {formatTime(timeRemaining || 0)}
        </Text>
      </Box>
    </HStack>
  );
};

// Add this CSS animation
const style = document.createElement("style");
style.textContent = `
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.02); }
    100% { transform: scale(1); }
  }
`;
document.head.appendChild(style);
