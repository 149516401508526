import {
  Avatar,
  Box,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  Badge,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { FaSignOutAlt, FaCog, FaCoins, FaCreditCard } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { User } from "../../types/user-types";
import { useGame } from "../../hooks/use-game";
import { useAppDispatch } from "../../redux-action/hooks/useTypedSelector";
import { useApiService } from "../../redux-action/api.service";
import { logoutUser } from "../../redux-action/slices/auth-slice";
import { EndGameModal } from "../spelling/game-play/EndGameModal";
import { formatNumberWithCommas } from "../../common/utils/currency-formatter";

type UserMenuProps = {
  user: User;
};

export const UserMenu = ({ user }: UserMenuProps) => {
  const api = useApiService();
  const dispatch = useAppDispatch();
  const { user: gameUser } = useGame();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleLogout = async () => {
    try {
      await api.service("auth").signOut();
      await dispatch(logoutUser());
      window.location.href = "/";
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
      <Tooltip label="Account Credits" placement="bottom">
        <MenuButton
          as={Box}
          cursor="pointer"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <HStack
            spacing={3}
            p={2}
            rounded="md"
            _hover={{ bg: "gray.50" }}
            transition="all 0.2s"
          >
            <Tooltip label="Click to add credits" placement="bottom">
              <Badge
                as={RouterLink}
                to="/auth/credit"
                display="flex"
                alignItems="center"
                px={2}
                py={1}
                borderRadius="full"
                colorScheme="yellow"
                variant="subtle"
                cursor="pointer"
                transition="all 0.2s"
                _hover={{
                  transform: 'scale(1.05)',
                  bg: 'yellow.100'
                }}
                border="1px solid"
                borderColor="blue.500"
              >
                <Icon as={FaCoins} color="yellow.500" mr={1} />
                <Text>{formatNumberWithCommas(user.credit ?? 0)}</Text>
              </Badge>
            </Tooltip>
            <Avatar
              size="sm"
              name={user.firstName ?? gameUser?.firstName}
              src=""
              bg="blue.500"
              color="white"
            />
            <Text fontSize="sm" fontWeight={500}>
              {user.firstName ?? gameUser?.firstName}
            </Text>
          </HStack>
        </MenuButton>
      </Tooltip>
      <MenuList shadow="lg" rounded="md" py={1}>
        {user && !gameUser && (
          <>
            <MenuItem
              as={RouterLink}
              to="/auth/credit"
              icon={<Icon as={FaCreditCard} color="yellow.500" />}
              _hover={{ bg: 'yellow.50' }}
            >
              <Flex justify="space-between" align="center" width="100%">
                <Text>Buy Credits</Text>
                <Badge colorScheme="yellow" ml={2}>
                  <HStack spacing={1}>
                    <Icon as={FaCoins} />
                    <Text>{formatNumberWithCommas(user.credit ?? 0)}</Text>
                  </HStack>
                </Badge>
              </Flex>
            </MenuItem>
            <MenuItem
              icon={<Icon as={FaCog} />}
              as={RouterLink}
              to="/auth/settings"
            >
              Settings
            </MenuItem>
            <Divider my={1} />
            <MenuItem
              icon={<Icon as={FaSignOutAlt} color="red.500" />}
              onClick={handleLogout}
              color="red.500"
              _hover={{ bg: "red.50" }}
            >
              Sign out
            </MenuItem>
          </>
        )}
        {gameUser && (
          <>
            <EndGameModal />
          </>
        )}
      </MenuList>
    </Menu>
  );
};
