import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Icon,
  Button,
} from "@chakra-ui/react";
import { FaTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ContentCard from "../nav/ContentCard";
import { Background } from "../../common/background/Background";

export const PaymentCancel = () => {
  const navigate = useNavigate();

  return (
    <ContentCard bg="transparent">
      <Background />
      <Container maxW="container.md" py={16}>
        <VStack spacing={8} textAlign="center">
          <Icon as={FaTimesCircle} w={20} h={20} color="red.500" />
          <Heading size="2xl" color="white">
            Payment Cancelled
          </Heading>
          <Text fontSize="xl" color="gray.200">
            Your payment was cancelled. No charges were made.
          </Text>
          <Box pt={8}>
            <Button
              size="lg"
              colorScheme="blue"
              onClick={() => navigate("/auth/credit")}
            >
              Try Again
            </Button>
          </Box>
        </VStack>
      </Container>
    </ContentCard>
  );
}; 