import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  useToast,
  Spinner,
  Icon,
  VStack,
  Heading,
  Container,
  RadioGroup,
  Radio,
  HStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Instructions } from "./Instructions";
import {  useParams } from "react-router-dom";
import countryList from "react-select-country-list";
import { useEffect, useMemo, useState } from "react";
import { CustomSelect } from "../../components/CustomSelect";
import ContentCard from "../nav/ContentCard";
import { yupResolver } from "@hookform/resolvers/yup";
import { StartSpellingGameSchema } from "./schema";
import { Background } from "../../common/background/Background";
import { api } from '../../redux-action/api.service';
import { useAppDispatch } from '../../redux-action/hooks/useTypedSelector';
import { setGameData, setError, setLoading } from '../../redux-action/slices/game-slice';
import { FaCheckCircle, FaChartLine } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { MotionElement } from "../../components/MotionElement";
import { AgeGroups } from "../../types/age-group-types";

export const Spell = () => {
  const countryOptions = useMemo(() => countryList().getData(), []);
  const { gameId } = useParams();
  const toast = useToast();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(StartSpellingGameSchema),
  });

  const [isComplete, setIsComplete] = useState(false);

  const checkExpectedUsers = async () => {
    if (gameId) {
      try {
        const isExpectedUsers = await api.service(`game-scores/check-expectedUsers/${gameId}`).find();
        setIsComplete(isExpectedUsers);
      } catch (error) {
        console.error('Error checking game completion:', error);
      }
    }
  };

  useEffect(() => {
    checkExpectedUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  const onSubmitHandler = async (data: StartSpellingGameSchema) => {
    try {
      dispatch(setLoading(true));
      
      if (gameId) {
        const response = await api.service('game-scores').create({
          ...data,
          isPublic: data.isPublic === 'true',
          gameId: gameId,
        });

        await dispatch(setGameData(response));
        
        toast({
          title: "Game created.",
          description: "We've created your game for you.",
          status: "success",
          position: "top-right",
          duration: 9000,
          isClosable: true,
        });

        setTimeout(() => {
          window.location.href = '/spelling';
        }, 1000);
      } else {
        dispatch(setError('Invite link not found'));
        toast({
          title: "Invite link not found.",
          description: "We've not found your game.",
          status: "error",
          position: "top-right",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      dispatch(setError(error.message));
      console.error(error.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  if (isComplete) {
    return (
      <ContentCard bg="transparent">
        <Background />
        <Container maxW="4xl" py={20} bg={"white"} p={4} rounded={"lg"} shadow={"md"}>
          <MotionElement type="fadeInY">
            <VStack spacing={8} textAlign="center">
              <Icon 
                as={FaCheckCircle} 
                w={20} 
                h={20} 
                color="green.400"
                mb={4}
              />
              <Heading 
                size="xl"
                bgGradient="linear(to-r, green.400, teal.500)"
                bgClip="text"
              >
                Memory IQ Test Complete!
              </Heading>
              <Text fontSize="xl" color="gray.600">
                The required number of participants have completed this Memory IQ test.
              </Text>
              <Box 
                p={6} 
                bg="white" 
                rounded="xl"  
                maxW="2xl"
                w="full"
              >
                <VStack spacing={4}>
                  <Text fontSize="lg">
                    You can now check your IQ score and see how you compare to others!
                  </Text>
                  <Button
                    as={RouterLink}
                    to="/my-iq-score"
                    size="lg"
                    colorScheme="blue"
                    leftIcon={<FaChartLine />}
                    _hover={{
                      transform: 'translateY(-2px)',
                      boxShadow: 'lg',
                    }}
                    transition="all 0.2s"
                  >
                    Check Your IQ Score
                  </Button>
                </VStack>
              </Box>
              <Text color="gray.500" fontSize="sm">
                Thank you for participating in our Memory IQ test!
              </Text>
            </VStack>
          </MotionElement>
        </Container>
      </ContentCard>
    );
  }

  return (
    <ContentCard bg={"transparent"}>
       <Background isDynamic={true} />
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        {/* {data ? <InvitePlayers inviteId={inviteId} /> : ""} */}

        <Box rounded={"lg"} bg={"gray.50"} boxShadow={"lg"} p={8}>
          <Instructions />
          <Box as={"form"} mt={0} onSubmit={handleSubmit(onSubmitHandler)}>
            <Stack spacing={4} mt={5}>
              <FormControl isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  {...register("firstName")}
                  id="userName"
                  placeholder="First Name"
                  required
                  isInvalid={!!errors.firstName}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  {...register("lastName")}
                  id="userName"
                  placeholder="Last Name"
                  required
                  isInvalid={!!errors.lastName}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  type="email"
                  {...register("email")}
                  placeholder="Your email address"
                  id="email"
                  isInvalid={!!errors.email}
                  required
                  mb={4}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Country Location</FormLabel>
                <CustomSelect
                  register={register}
                  id="country"
                  placeholder="Select Country"
                  defaultValue={""}
                  options={countryOptions}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Age Group</FormLabel>
                <CustomSelect
                  register={register}
                  id="ageGroup"
                  placeholder="Select Age Group"
                  defaultValue={""}
                  options={AgeGroups}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Would you like to share your score publicly?</FormLabel>
                <RadioGroup defaultValue="false">
                  <HStack spacing={6} py={2}>
                    <Radio 
                      {...register("isPublic")}
                      value="true"
                      colorScheme="green"
                      size="lg"
                    >
                      <Text fontSize="md" color="gray.700">
                        Yes, share my score
                      </Text>
                    </Radio>
                    <Radio 
                      {...register("isPublic")}
                      value="false"
                      colorScheme="red"
                      size="lg"
                    >
                      <Text fontSize="md" color="gray.700">
                        No, keep it private
                      </Text>
                    </Radio>
                  </HStack>
                </RadioGroup>
                <Text fontSize="sm" color="gray.500" mt={1}>
                  Public scores may appear in leaderboards and statistics
                </Text>
              </FormControl>
              <Stack spacing={10}>
                <Text textAlign={"left"} color={"blue.600"}>
                  Your name will appear on your certificate exactly as you enter
                  it. We will send your certificate to your email.
                </Text>
                {/* <Text textAlign={"left"}>
                  Your Journey to Unleash Your Inner Memory Maestro Begins Here
                </Text> */}
                <Button colorScheme={"blue"} type="submit">
                  {isSubmitting ? <Spinner /> : "Play"}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </ContentCard>
  );
};
