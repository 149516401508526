import {
  Box,
  Button,
  Container,
  Stack,
  Heading,
  Text,
  Link,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginSchema } from './schema';
import { FormInput } from '../../components/form/FormInput';
import { useAuth } from '../../hooks/useAuth';
import { GoogleSigInButton } from './GoogleSigInButton';

export const Login = () => {
  const { handleLogin } = useAuth();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<LoginSchema>({
    resolver: yupResolver(LoginSchema),
    mode: 'onChange'
  });

  const onSubmit = (data: LoginSchema) => {
    handleLogin(data);
  };

  return (
    <Box
      bgSize="cover"
      minH="100vh"
      py={{ base: '12', md: '24' }}
      bg={"#dbeafe"}
    >
      <Container maxW="lg" px={{ base: '0', sm: '8' }}>
        <Stack spacing="8">
          <Stack spacing="6" align="center">
            <Heading fontSize={{base: "24px", md: "32px"}} mb={0}>
              Welcome Back! 
          </Heading>
          <Text fontSize="16px" mb={5}>
            Sign in to continue to your account
          </Text>
          </Stack>
          <Box
            py={{ base: '0', sm: '8' }}
            px={{ base: '4', sm: '10' }}
            bg={"#FFF"}
            boxShadow={{ base: 'none', sm: 'md' }}
            borderRadius={{ base: 'none', sm: 'xl' }}
          >
            <Box as="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="6">
                <FormInput
                  label="Email"
                  name="email"
                  type="email"
                  register={register}
                  error={errors.email}
                />
                <FormInput
                  label="Password"
                  name="password"
                  type="password"
                  register={register}
                  error={errors.password}
                />
                <Button
                  type="submit"
                  colorScheme="blue"
                  size="lg"
                  fontSize="md"
                  isLoading={isSubmitting}
                >
                  Sign in
                </Button>
                <GoogleSigInButton />
              </Stack>
            </Box>
            <Stack pt="6" spacing="1" align="center">
              <Text>
                Don't have an account?{' '}
                <Link as={RouterLink} to="/auth/register" color="blue.500">
                  Register
                </Link>
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}; 