import { FC, useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";
import {
  Box,
  Heading,
  Text,
  Divider,
  VStack,
  Button,
  Flex,
  SimpleGrid,
  Icon,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { dateToString } from "../../../../common/utils/date-time";
import { addSpaceAfterEachWord } from "../../../../common/utils/helperFuntions";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Score } from "../../../../types/score-types";
import { FaDownload, FaChevronUp, FaChevronDown } from "react-icons/fa";
import { BrandLogo } from "../../../../components/BrandLogo";
import { useApiService } from "../../../../redux-action/api.service";
import { AIFeedback } from "../../../../types/ai-feedback-types";

type CertificateViewProps = {
  score: Score;
  correctAnswers: number;
  totalQuestions: number;
  data: { name: string; value: number }[];
  COLORS: string[];
};

export const CertificateView: FC<CertificateViewProps> = ({
  score,
  correctAnswers,
  totalQuestions,
  data,
  COLORS,
}) => {
  const api = useApiService();
  const [showRecommendations, setShowRecommendations] =
    useState<boolean>(false);
  const [userScore, setUserScore] = useState<Score>(score);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [aiFeedback, setAiFeedback] = useState<AIFeedback>();
  const toast = useToast();
  
  const refreshScore = async () => {
    setIsLoading(true);
    try {
      const response = await api
        .service(`game-scores/id/${score?.id}/email/${score?.email}`)
        .find();
      setUserScore(response);
      if (response.aiFeedback) {
        setAiFeedback(response.aiFeedback);
      }
    } catch (error) {
      console.error('Error refreshing score:', error);
      toast({
        title: 'Error',
        description: 'Failed to refresh score',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowAnalysis = async () => {
    const newState = !showRecommendations;
    setShowRecommendations(newState);
    if (newState && !aiFeedback?.comments) {
      await refreshScore();
    }
  };

  const handleSavePDF = async () => {
    const certificateElement = document.getElementById("certificate-content");
    if (!certificateElement) return;

    const canvas = await html2canvas(certificateElement, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("certificate.pdf");
  };

  useEffect(() => {
    if (userScore?.aiFeedback) {
      setAiFeedback(JSON.parse(userScore.aiFeedback.toString()) as AIFeedback);
    }
  }, [userScore]);

  return (
    <>
      <Flex justify="flex-end" mb={4} bg={"transparent"}>
        <Button
          leftIcon={<FaDownload />}
          colorScheme="blue"
          size="md"
          onClick={handleSavePDF}
          boxShadow="sm"
          _hover={{ transform: "translateY(-1px)", boxShadow: "md" }}
        >
          Download Certificate
        </Button>
      </Flex>

      <Box
        borderWidth="1px"
        borderColor="gray.200"
        borderRadius="xl"
        p={12}
        maxW="900px"
        mx="auto"
        bg="white"
        boxShadow="xl"
        position="relative"
        id="certificate-content"
        style={{ pageBreakInside: "avoid" }}
        _before={{
          content: '""',
          position: "absolute",
          top: "10px",
          left: "10px",
          right: "10px",
          bottom: "10px",
          border: "2px solid",
          borderColor: "blue.100",
          borderRadius: "lg",
          pointerEvents: "none",
        }}
      >
        <VStack spacing={8} align="center">
          {/* Logo Section */}
          <Box textAlign="center">
            <Heading fontSize="4xl" color="blue.600" letterSpacing="wider">
              <BrandLogo size={{ base: 18, md: 32 }} />
            </Heading>
          </Box>

          {/* Title Section */}
          <VStack spacing={2}>
            <Heading
              as="h1"
              size="xl"
              color="gray.800"
              textTransform="uppercase"
              letterSpacing="wide"
            >
              Certificate of Achievement
            </Heading>
            <Divider width="60%" borderColor="blue.200" borderWidth="2px" />
          </VStack>

          {/* Recipient Section */}
          <VStack spacing={4}>
            <Text fontSize="xl" color="gray.600">
              This certifies that
            </Text>
            <Heading
              size="lg"
              color="blue.700"
              textTransform="capitalize"
              fontFamily="serif"
            >
              {`${userScore?.user?.firstName} ${userScore?.user?.lastName}`}
            </Heading>
            <Text fontSize="lg" color="gray.700">
              has successfully completed the <Text as="b">Memory IQ</Text> Test
              with an impressive score of
            </Text>
          </VStack>

          {/* Score Section */}
          <Box width="100%" height="300px">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={data}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={80}
                  outerRadius={100}
                  fill="#8884d8"
                  label={({ value, name }) => `${name}: ${value}`}
                >
                  {data.map((entry, index) => (
                    <Cell key={index} fill={COLORS[index % COLORS.length]} />
                  ))}
                  <Label
                    content={() => (
                      <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        dominantBaseline="central"
                        fill="#000"
                        fontSize="50"
                        fontWeight="600"
                      >
                        {userScore?.memoryIQScore ?? 0}%
                      </text>
                    )}
                    position="center"
                  />
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>

          {/* Details Section */}
          <VStack spacing={4} width="100%" maxW="md">
            <Box
              p={4}
              bg="blue.50"
              borderRadius="md"
              width="100%"
              textAlign="center"
            >
              <Text fontSize="lg" color="gray.700">
                Correctly answered{" "}
                <Text as="span" fontWeight="bold" color="blue.600">
                  {correctAnswers}
                </Text>{" "}
                out of{" "}
                <Text as="span" fontWeight="bold" color="blue.600">
                  {totalQuestions}
                </Text>{" "}
                questions
              </Text>
            </Box>

            <SimpleGrid columns={2} spacing={4} width="100%">
              <Box p={3} bg="#f5f5f9" borderRadius="md">
                <Text fontWeight="semibold" color="gray.600">
                  Time Taken
                </Text>
                <Text color="gray.800">{userScore?.timeCompleted ?? ""}</Text>
              </Box>
              <Box p={3} bg="#f5f5f9" borderRadius="md">
                <Text fontWeight="semibold" color="gray.600">
                  Test Type
                </Text>
                <Text color="gray.800" textTransform="capitalize">
                  {addSpaceAfterEachWord(userScore?.game?.gameCategory ?? "")}
                </Text>
              </Box>
            </SimpleGrid>
          </VStack>

          {/* AI Feedback Section */}
          {(aiFeedback || userScore?.aiFeedback) && (
            <>
              <Button
                onClick={handleShowAnalysis}
                colorScheme="blue"
                variant="outline"
                size="sm"
                isLoading={isLoading}
                loadingText="Loading Analysis"
                rightIcon={
                  showRecommendations ? (
                    <Icon as={FaChevronUp} />
                  ) : (
                    <Icon as={FaChevronDown} />
                  )
                }
              >
                {showRecommendations ? "Hide Analysis" : "Show Analysis"}
              </Button>

              {showRecommendations && (
                <VStack
                  // spacing={4}
                  bg="blue.50"
                  p={4}
                  borderRadius="md"
                  borderWidth="1px"
                  borderColor="blue.200"
                  width="100%"
                >
                  <Heading size="md" color="blue.700">
                    AI Performance Analysis
                    {isLoading && <Spinner size="sm" ml={2} color="blue.500" />}
                  </Heading>
                  <Box width="100%" borderWidth="1px blue solid" textAlign="left">
                    <Text
                      color="gray.700"
                      fontSize="md"
                      lineHeight="tall"
                      dangerouslySetInnerHTML={{
                        __html: (aiFeedback || aiFeedback)?.comments || "nn",
                      }}
                    />
                  </Box>
                  <Box width="100%" borderWidth="1px blue solid" textAlign="left">
                    <Text fontWeight="semibold" color="blue.700" mb={2}>
                      Remarks: 
                    </Text>
                    <Text
                      as="span"
                      color="gray.700"
                      fontSize="md"
                      lineHeight="tall"
                      dangerouslySetInnerHTML={{
                        __html: (aiFeedback || userScore?.aiFeedback)?.generalAdvice || "",
                      }}
                    />
                  </Box>
                </VStack>
              )}
            </>
          )}

          {/* Footer Section */}
          <VStack spacing={2} mt={8}>
            <Text fontSize="md" color="gray.600">
              Issued on {dateToString(userScore?.createdAt ?? "")}
            </Text>
            <Divider width="80%" borderColor="gray.200" />
            <Text fontSize="sm" color="gray.500">
              Verified by TheLastSpellingBee | www.thelastspellingbee.com
            </Text>
          </VStack>
        </VStack>
      </Box>
    </>
  );
};
