import {
  Text,
  Box,
  ListItem,
  UnorderedList,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react";
import { FaStar, FaRegStar } from "react-icons/fa";

interface GameLevelExplanationProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GameLevelExplanation: React.FC<GameLevelExplanationProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg">Question Levels Guide</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <SimpleGrid columns={3} spacing={4}>
            {/* Basic Levels */}
            <Box p={4} bg="white" borderRadius="md" boxShadow="sm" border="1px" borderColor="blue.100">
              <Heading size="sm" mb={4} color="blue.600" display="flex" alignItems="center">
                Basic Levels
                <Box ml={2}>
                  <Icon as={FaStar} color="blue.400" />
                  <Icon as={FaRegStar} color="blue.400" />
                  <Icon as={FaRegStar} color="blue.400" />
                </Box>
              </Heading>
              
              <Box mb={4}>
                <Text fontWeight="bold" color="gray.700">CHARACTERS (Level 1)</Text>
                <UnorderedList color="gray.600" spacing={1}>
                  <ListItem>Convert letters to their position numbers</ListItem>
                  <ListItem>Example: "HELLO" → "8 5 12 12 15"</ListItem>
                  <ListItem>Perfect for beginners</ListItem>
                </UnorderedList>
              </Box>

              <Box mb={4}>
                <Text fontWeight="bold" color="gray.700">NUMBERS (Level 2)</Text>
                <UnorderedList color="gray.600" spacing={1}>
                  <ListItem>Convert numbers to letters</ListItem>
                  <ListItem>Example: "8 5 12 12 15" → "HELLO"</ListItem>
                  <ListItem>Good for number recognition</ListItem>
                </UnorderedList>
              </Box>

              <Box>
                <Text fontWeight="bold" color="gray.700">RANDOM (Level 3)</Text>
                <UnorderedList color="gray.600" spacing={1}>
                  <ListItem>Mix of CHARACTERS and NUMBERS questions</ListItem>
                  <ListItem>Random selection between the two types</ListItem>
                  <ListItem>Tests flexibility in thinking</ListItem>
                </UnorderedList>
              </Box>
            </Box>

            {/* Advanced Levels */}
            <Box p={4} bg="white" borderRadius="md" boxShadow="sm" border="1px" borderColor="purple.100">
              <Heading size="sm" mb={4} color="purple.600" display="flex" alignItems="center">
                Advanced Levels
                <Box ml={2}>
                  <Icon as={FaStar} color="purple.400" />
                  <Icon as={FaStar} color="purple.400" />
                  <Icon as={FaRegStar} color="purple.400" />
                </Box>
              </Heading>
              
              <Box mb={4}>
                <Text fontWeight="bold" color="gray.700">Advanced Characters (Level 4)</Text>
                <UnorderedList color="gray.600" spacing={1}>
                  <ListItem>Complex letter patterns</ListItem>
                  <ListItem>Example: "HE2LLO" → "8 5 2 12 12 15"</ListItem>
                  <ListItem>Includes special characters and numbers</ListItem>
                </UnorderedList>
              </Box>

              <Box mb={4}>
                <Text fontWeight="bold" color="gray.700">Advanced Numbers (Level 5)</Text>
                <UnorderedList color="gray.600" spacing={1}>
                  <ListItem>Complex number sequences</ListItem>
                  <ListItem>Example: "8-5-12-12-15" → "HELLO"</ListItem>
                  <ListItem>Includes mathematical operations</ListItem>
                </UnorderedList>
              </Box>

              <Box>
                <Text fontWeight="bold" color="gray.700">Advanced Mixed (Level 6)</Text>
                <UnorderedList color="gray.600" spacing={1}>
                  <ListItem>Combination of letters and numbers</ListItem>
                  <ListItem>Example: "H3LL0" ↔ "8-3-12-12-0"</ListItem>
                  <ListItem>Requires both conversion skills</ListItem>
                </UnorderedList>
              </Box>
            </Box>

            {/* Expert Levels */}
            <Box p={4} bg="white" borderRadius="md" boxShadow="sm" border="1px" borderColor="red.100">
              <Heading size="sm" mb={4} color="red.600" display="flex" alignItems="center">
                Expert Levels
                <Box ml={2}>
                  <Icon as={FaStar} color="red.400" />
                  <Icon as={FaStar} color="red.400" />
                  <Icon as={FaStar} color="red.400" />
                </Box>
              </Heading>
              
              <Box mb={4}>
                <Text fontWeight="bold" color="gray.700">RANDOM + Advanced Characters (Level 7)</Text>
                <UnorderedList color="gray.600" spacing={1}>
                  <ListItem>Mix of basic and advanced character questions</ListItem>
                  <ListItem>Unpredictable pattern switches</ListItem>
                  <ListItem>Tests quick thinking and adaptation</ListItem>
                </UnorderedList>
              </Box>

              <Box mb={4}>
                <Text fontWeight="bold" color="gray.700">RANDOM + Advanced Numbers (Level 8)</Text>
                <UnorderedList color="gray.600" spacing={1}>
                  <ListItem>Mix of basic and advanced number questions</ListItem>
                  <ListItem>Complex number patterns</ListItem>
                  <ListItem>Mathematical and logical thinking required</ListItem>
                </UnorderedList>
              </Box>

              <Box>
                <Text fontWeight="bold" color="gray.700">Ultimate Mixed (Level 9)</Text>
                <UnorderedList color="gray.600" spacing={1}>
                  <ListItem>Combines all previous levels</ListItem>
                  <ListItem>Most challenging question types</ListItem>
                  <ListItem>Tests mastery of all conversion skills</ListItem>
                </UnorderedList>
              </Box>
            </Box>
          </SimpleGrid>

          <Box mt={4} p={3} bg="gray.50" borderRadius="md">
            <Text fontSize="sm" color="gray.600">
              Note: Higher levels require more time per question. Choose the level that matches your skill and available time.
            </Text>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
