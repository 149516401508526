import { useEffect } from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Icon,
  Button,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ContentCard from "../nav/ContentCard";
import { Background } from "../../common/background/Background";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux-action/store";
import { updateUserCredits } from "../../redux-action/slices/auth-slice";
import { useApiService } from "../../redux-action/api.service";

export const PaymentSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const api = useApiService();

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        // Get session_id from URL
        const params = new URLSearchParams(window.location.search);
        const sessionId = params.get('session_id');

        if (sessionId) {
          // Verify payment with backend
          const response = await api.service(`payments/${sessionId}`).find();
          
          // Update credits in Redux store
          if (response.credits) {
            dispatch(updateUserCredits(response.credits));
          }
        }
      } catch (error) {
        console.error("Error verifying payment:", error);
      }
    };

    verifyPayment();
  }, [dispatch, api]);

  return (
    <ContentCard bg="transparent">
      <Background />
      <Container maxW="container.md" py={16}>
        <VStack spacing={8} textAlign="center">
          <Icon as={FaCheckCircle} w={20} h={20} color="green.500" />
          <Heading size="2xl" color="white">
            Payment Successful!
          </Heading>
          <Text fontSize="xl" color="gray.200">
            Your credits have been added to your account
          </Text>
          <Box pt={8}>
            <Button
              size="lg"
              colorScheme="blue"
              onClick={() => navigate("/dashboard")}
            >
              Return to Dashboard
            </Button>
          </Box>
        </VStack>
      </Container>
    </ContentCard>
  );
}; 