import { FC } from "react";
import { Box, Button, Center, Link, Stack } from "@chakra-ui/react";
import ContentCard from "../../../nav/ContentCard";
import { CertificateView } from "./CertificateView";
import { Score } from "../../../../types/score-types";

type ResultProps = {
  score: Score;
  isBackBtn?: boolean;
};

export const EducationResult: FC<ResultProps> = ({
  score: gameScore,
  isBackBtn = false,
}) => {
  const totalQuestions = gameScore?.game?.numberOfQuestions ?? 0;
  const score = gameScore?.score;
  const correctAnswers = Math.floor(score / 5);
  const incorrectAnswers = totalQuestions - correctAnswers;

  const data = [
    { name: "Correct", value: correctAnswers },
    { name: "Incorrect", value: incorrectAnswers },
  ];

  const COLORS = ["#2f855a", "#c53030"];

  return (
    <ContentCard bg={"transparent"}>
      <Box>
        {isBackBtn ? (
          <Box textAlign="left">
            <Link
              href={`/auth/game-result/${gameScore.gameId}`}
              color="#2b6cb0"
              fontSize={{ base: 14, sm: 17 }}
              me="10px"
            >
              <Button h={8} colorScheme="blue" variant="outline">
                Back
              </Button>
            </Link>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Center>
      <Stack direction={"column"}>
        <CertificateView
          score={gameScore}
          correctAnswers={correctAnswers}
          totalQuestions={totalQuestions}
          data={data}
          COLORS={COLORS}
        />
        </Stack>
      </Center>
    </ContentCard>
  );
};
