  import { Box } from "@chakra-ui/react";
import { AboutSection } from "./about-section";
import { FAQs } from "./FAQs";
import { DisplayLatestResults } from "../spelling/game-play/DisplayLatestResults";
import { Hero } from "./hero";
import DefaultContentCard from "../nav/DefaultCard";

export const Home = () => {
  return (
    <DefaultContentCard bg={"transparent"} p={0}>
     <Box w={"100%"}>
       <Hero />
      <AboutSection />

      <FAQs />

      <DisplayLatestResults paginatePerPage={10} mt={0} />
     </Box>
    </DefaultContentCard>
  );
};
