export const QuestionType = [
  'CHARACTERS',
  'NUMBERS',
  'RANDOM',
  'COMPLEX',
  'AdvancedCharacters',
  'AdvancedNumbers',
  'AdvancedCharactersAndNumbers',
  'RANDOMAndAdvancedCharacters',
  'RANDOMAndAdvancedNumbers',
  'RANDOMAndAdvancedCharactersAndNumbers',
] as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type QuestionType = (typeof QuestionType)[number];

export const CATEGORY_OPTIONS = [
  "Education",
  "TeamBonding",
  "Entertainment",
  "GeneralKnowledge",
  "Others"
] as const;

export type CategoryOption = (typeof CATEGORY_OPTIONS)[number];

export const QUESTION_LEVEL_LABELS: Record<QuestionType, string> = {
  'CHARACTERS': 'Level 1 - Basic Characters',
  'NUMBERS': 'Level 2 - Basic Numbers',
  'RANDOM': 'Level 3 - Basic Mixed',
  'COMPLEX': 'Level 3+ - Complex Mixed',
  'AdvancedCharacters': 'Level 4 - Advanced Characters',
  'AdvancedNumbers': 'Level 5 - Advanced Numbers',
  'AdvancedCharactersAndNumbers': 'Level 6 - Advanced Mixed',
  'RANDOMAndAdvancedCharacters': 'Level 7 - Random + Advanced Characters',
  'RANDOMAndAdvancedNumbers': 'Level 8 - Random + Advanced Numbers',
  'RANDOMAndAdvancedCharactersAndNumbers': 'Level 9 - Ultimate Mixed',
} as const;

// Helper function to get level number and name from question type
export const getLevelNumber = (questionType: QuestionType): number => {
  const label = QUESTION_LEVEL_LABELS[questionType];
  const match = label.match(/Level (\d+)/);
  return match ? parseInt(match[1], 10) : 0;
};